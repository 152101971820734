import { trackEvent } from '@lib/tracking'

// This mixin contains all the possible parameters to filter and sort a table
// not all parameters are used on all tables, but it will ignore ones not
// necessary.  this allows us to DRY the code and consolidate logic into a
// single place
export default {
  data() {
    return {
      initialized: false,
      // setting this to true is a way to trigger the watchers to fire
      // and can be used to kick off the data load without explicitly
      // calling it in the mounted method
      redraw: false,
      // these are the fields associated with the recipient table
      // @TODO This doesn't currently support the volunteers param, which is needed for AdvocatesTable to work
      table_properties: [
        'q',
        'type',
        'per_page',
        'page',
        'sort_by',
        'sort_dir',
        'responded',
        'view',
      ],
      // these are the variable that gets set when a filter is selected
      filter_properties: [
        'size_group',
        'sector_id',
        'seniority',
        'recipient_role_id',
        'volunteer_activity',
        'survey_id',
        'tag',
        'asset_list_id',
        'edit_asset_list_id',
        'classifier_value_ids',
        'type',
      ],
      type: this.$route.query.type || '',
      view: this.$route.query.view || 'card',
      q: this.$route.query.q || '',
      sort_by: this.$route.query.sort_by || 'last_name',
      sort_dir: this.$route.query.sort_dir || 'desc',
      page: Number(this.$route.query.page) || 1,
      per_page: Number(this.$route.query.per_page) || 25,
      tab: Number(this.$route.query.tab) || 0,
      tag: this.$route.query.tag || '',
      question_id: this.$route.query.question_id || '',
      responded: 1,
      asset_list_id: this.$route.query.asset_list_id || null,
      edit_asset_list_id: this.$route.query.edit_asset_list_id || null,
      survey_id: this.$route.query.survey_id || '',
      size_group: this.$route.query.size_group || '',
      classifier_value_ids: this.$route.query.classifier_value_ids || '',
      sector_id: this.$route.query.sector_id || '',
      seniority: this.$route.query.seniority || '',
      recipient_role_id: this.$route.query.recipient_role_id || '',
      volunteer_activity: this.$route.query.volunteer_activity || '',
    }
  },
  methods: {
    resetPage() {
      this.page = 1
    },
  },
  computed: {
    all_properties() {
      return this.table_properties.concat(this.filter_properties)
    },
    filter_watchers() {
      return this.filter_properties.map((p) => this[p])
    },
    all_watchers() {
      // note comment in data section about `redraw`
      return this.all_properties.map((p) => this[p]).concat([this.redraw == true])
    },
    // this will return an object of all the variables that have been set
    the_query() {
      var query = {}
      this.all_properties.forEach((q) => {
        if (this[q] === null || this[q] === '') return
        query[q] = this[q]
      })
      return query
    },
  },
  watch: {
    filter_watchers() {
      // this watcher can also be defined on the component
      // and implement specific logic
      this.resetPage()
    },
    all_watchers() {
      // we keep an eye on the properties and push them to the url if they change
      trackEvent('assets searched', this.the_query)
      if (this.initialized) this.$router.push({ query: Object.assign({}, this.the_query) })
      this.initialized = true
    },
  },
}
