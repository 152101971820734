<template lang="pug">
  SiteLayout(:sideBar="sideBarType()")
    #assets_show.assets_show(v-if='content_asset && account')
      // ShareAssetModal will be replaced by ShareAssetModalNew
      ShareAssetModal
      ShareAssetModalNew
      // ShareMatrixxChartModal will be moved onto the ShareAssetModalNew component soon
      ShareMatrixxChartModal
      //- @MITCH This should be at least two different components...
      .ueid_page_content(v-if='!basic_layout_asset')
        header.user-research-library-asset-header(:class='{ dark_text: useDarkText }' :style='header_style')
          .back-link-container
            a.back-link(:href='back_url')
              BackArrow
              span {{ back_text }}

        section.main_page_content
          .first_content
            h1 <span>UEID:</span> {{content_asset.identifier}}
            .ueid-asset-container
              TestimonialBasic(:renderable-testimonial='content_asset' :account='account' v-if='content_asset.type=="RenderableTestimonial"')
              BarChartBasic(:content-asset='content_asset' :account='account' v-if='content_asset.type=="ChartAsset"')
              StatBasic(:content-asset='content_asset' :account='account' v-if='content_asset.type=="StatAsset"')
              MatrixxChartBasic(:matrixxChart='content_asset' :account='content_asset.account' v-if='content_asset.type=="MatrixxChart"')
              VideoLinkAsset(:video-link-asset='content_asset' :account='content_asset.account' v-if='content_asset.type=="VideoLinkAsset"')

          .second_content(v-if="showAboutSection(content_asset)")
            h5 About This Research
            p This data is from a survey of {{content_asset.response_count}} {{account.name}} users, and was verified by UserEvidence on {{content_asset.verified_at | dayjs('MMMM DD, YYYY')}}.
            .ueid-button-container
              button.button.button-primary(v-if='true' @click="showModal()") Download
          .second_content(v-else-if="content_asset.type=='FileAsset'")
            .ueid-button-container.file-asset-button
              button.button.button-primary(v-if='true' @click="downloadPdf(content_asset.id)") Download PDF

        section.overview_information
          .customer_information
            h5 About {{account.name}}
            p {{account.introduction}}
          .ue_information
            h5 About UserEvidence
            p {{about_ue}}

      section.cs(v-else)
        div(v-if="content_asset.type=='FileAsset'")
          FileAssetPreviewer(:file-asset='content_asset' :account="account" renderAsList)

        div(v-else-if='content_asset.type=="CustomerSpotlight"')
          CustomerSpotlight(:customer_spotlight='content_asset' :account="account")
          .spotlight_fab(@click='$modal.show("share_customer_spotlight_modal", { customer_spotlight: content_asset })')
            DownloadIcon

          ShareCustomerSpotlightModal
        SurveySpotlight(:content_asset='content_asset' v-if='content_asset.type=="SurveySpotlightAsset"')
        SurveySummary(:contentAsset='content_asset' :account='account' v-if='content_asset.type=="SurveySummary"')
  </template>

<script lang="ts">
import axios from '@lib/apiClient'
import TestimonialBasic from '@components/TestimonialBasic.vue'
import BarChartBasic from '@components/BarChartBasic.vue'
import StatBasic from '@components/StatBasic.vue'
import SurveySpotlight from '@components/SurveySpotlight.vue'
import CustomerSpotlight from '@components/CustomerSpotlight.vue'
import SurveySummary from '@components/SurveySummary.vue'
import ShareAssetModal from '@components/ShareAssetModal.vue'
import ShareAssetModalNew from '@components/ShareAssetModalNew.vue'
import ShareMatrixxChartModal from '@components/ShareMatrixxChartModal.vue'
import ShareCustomerSpotlightModal from '@components/ShareCustomerSpotlightModal.vue'
import BackArrow from '@components/graphics/BackArrow.vue'
import DownloadIcon from '@components/graphics/DownloadIcon.vue'
import MatrixxChartBasic from '@components/MatrixxChartBasic.vue'
import VideoLinkAsset from '@components/VideoLinkAsset.vue'
import FileAsset from '@components/FileAsset.vue'
import FileAssetPreviewer from '@components/FileAssetPreviewer.vue'
import SiteLayout from '@site/components/SiteLayout.vue'

import { gradientColors, useDarkText } from '@lib/colors'
import { useAccountStore } from '@app/stores/AccountStore'
import { downloadFileAsset } from '@app/api.js'
import { reportError } from '@lib/errorHandler'
import { trackEvent, assetEventData } from '@lib/tracking'

export default {
  components: {
    TestimonialBasic,
    BarChartBasic,
    StatBasic,
    CustomerSpotlight,
    SurveySpotlight,
    SurveySummary,
    ShareAssetModal,
    BackArrow,
    ShareCustomerSpotlightModal,
    DownloadIcon,
    MatrixxChartBasic,
    VideoLinkAsset,
    FileAsset,
    ShareMatrixxChartModal,
    ShareAssetModalNew,
    FileAssetPreviewer,
    SiteLayout,
  },
  props: {
    content_asset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { account: null }
  },
  head() {
    return {
      title: `UEID: ${this.content_asset.identifier} from ${this.account.name} | User Research by UserEvidence`,
      meta: [
        { hid: 'twitter:title', name: 'twitter:title', content: this.meta_title },
        { hid: 'twitter:description', name: 'twitter:description', content: this.about_ue },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:image', name: 'twitter:image', content: this.content_asset.og_image_url },
        { hid: 'twitter:image:alt', name: 'twitter:image:alt', content: this.meta_title },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.meta_title },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.about_ue,
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: this.content_asset.og_image_url,
        },
        { hid: 'og:url', name: 'og:url', property: 'og:url', content: this.meta_url },
      ],
    }
  },
  computed: {
    the_title() {
      return `${this.content_asset.id} | UserEvidence`
    },
    header_style() {
      const [startColor, endColor] = this.gradientColors
      return {
        background: `linear-gradient(${startColor}, ${endColor})`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `right center`,
      }
    },
    back_url() {
      return this.$cookies.get('site-back-url') && this.$cookies.get('site-back-url').length > 5
        ? this.$cookies.get('site-back-url')
        : `/user-research-library/${this.account.slug}`
    },
    back_text() {
      return this.$cookies.get('site-back-text') && this.$cookies.get('site-back-text').length > 5
        ? this.$cookies.get('site-back-text')
        : `${this.account.name} Home`
    },
    footer_style() {
      return ['CustomerSpotlight', 'SurveySpotlightAsset'].includes(this.content_asset.type)
        ? 'customer_spotlight'
        : ''
    },
    is_pdf() {
      return (
        this.content_asset.variants.length > 0 &&
        this.content_asset.variants[0].type.indexOf('Pdf') > 0
      )
    },
    is_png() {
      return (
        this.content_asset.variants.length > 0 &&
        this.content_asset.variants[0].type.indexOf('Png') > 0
      )
    },
    basic_layout_asset() {
      return ['CustomerSpotlight', 'SurveySpotlightAsset', 'SurveySummary', 'FileAsset'].includes(
        this.content_asset.type,
      )
    },
    meta_title() {
      return `${this.account.name} Asset from UserEvidence`
    },
    meta_url() {
      return `https://www.userevidence.com/assets/${this.content_asset.identifier}`
    },
    about_ue() {
      return 'UserEvidence is a survey-based research platform that enables vendors to showcase feedback from real users of their products. UserEvidence independently verifies the identity of survey respondents and authenticity of response data.'
    },
    multi_page() {
      return this.content_asset.variants.filter((v) => v.type.indexOf('Multi') >= 0).length > 0
    },
    gradientColors,
    useDarkText,
  },
  methods: {
    sideBarType() {
      return (
        this.content_asset.type == 'FileAsset' || this.content_asset.type == 'CustomerSpotlight'
      )
    },
    showModal() {
      switch (this.content_asset.type) {
        case 'MatrixxChart':
          this.$modal.show('share_matrixx_chart_modal', { matrixx_chart: this.content_asset })
          break
        case 'StatAsset':
        case 'ChartAsset':
        case 'RenderableTestimonial':
          this.$modal.show('share_asset_modal_new', { content_asset: this.content_asset })
          break
        default:
          this.$modal.show('share_asset_modal', { content_asset: this.content_asset })
      }
    },
    downloadPdf(assetId) {
      downloadFileAsset(assetId)
        .then((resp) => {
          window.open(resp.data.download_url, '_blank')
        })
        .catch((e) => {
          reportError(e, 'downloadPdf', 'Site/Assets/Show')
          alert('Error downloading PDF file.')
        })
    },
    showAboutSection(contentAsset) {
      return contentAsset.type !== 'VideoLinkAsset' && contentAsset.type !== 'FileAsset'
    },
  },
  mounted() {
    // we are doing this here to get the app json for koala tracking.
    // note this request will fail if the user is not logged in via the app.
    // be aware that we'll be seeing 401's in the logs because of this
    useAccountStore().getApp()
    // note we are using the `track` instead of `trackView` because
    // we are getting better data using the custom event.
    trackEvent('asset viewed', assetEventData(this.content_asset))

    axios
      .get(`/api/accounts/${this.content_asset.account.id}.json`)
      .then((r) => (this.account = r.data.account))
  },
}
</script>
<style lang="sass" scoped>

.assets_show
  // header
    // this is broken, the account page is working as it should, see for reference
    //  - the styles are so messed up though thanks to max, we should refactor
    //  the markup and styles to be common between both.  i tried but it was too
    //  involved.  im putting this off for another day
    // background-image: url('/images/home/ue-email-signup-background-shape.svg')
    // background: url('../../../../images/home/ue-email-signup-background-shape.svg'), var(--header-gradient) !important

  .user-research-library-asset-header
    position: relative
    z-index: 1
    width: 100%
    height: 280px
    margin: 0
    padding: 0
    .back-link-container
      top: 24px
      display: flex
      width: 100%
      max-width: 1120px
      padding: 0 32px
      margin: 0 auto
      position: relative
      .back-link
        font-size: 14px
        font-weight: 800
        display: inline-flex
        align-items: center
        line-height: 1
        color: hsl(200, 100%, 100%)
        span
          opacity: 0.9
        svg
          height: 16px
          width: 16px
          margin-right: 8px
          opacity: 0.5
        &:hover
          svg, span
            opacity: 1

  .dark_text
    .back-link-container
      svg
        path
          stroke: hsl(200, 8%, 8%)
  .light-badge
    background-color: hsl(198, 24%, 92%)
    color: hsl(199, 12%, 36%)


  .content_asset, .bar_chart
    box-shadow: 0px 4px 24px hsla(200, 24%, 24%, 0.08), 0px 8px 8px hsla(200, 8%, 16%, 0.02), inset 0px -1px 0px hsl(200, 16%, 88%)
    .asset_branding .logos
      display: inline-flex
      justify-content: space-between
      width: 100%

  .content_asset_container .stat_header
    .star_icons
      display: inline-flex
    svg
      height: 32px
      width: 32px

  .vertical .chart .many_bars > .channel .bar .stat
    font-size: 18px !important
    span
      font-size: 16px !important

  .content_asset .asset_branding
    margin-bottom: 0

  .main_page_content, .overview_information
    width: 100%
    max-width: 1120px
    padding: 0 32px

  .main_page_content
    margin: 0 auto
    z-index: 2
    display: flex
    position: relative
    top: -96px
    h1
      font-weight: 800
      color: hsla(200, 100%, 100%, 0.4)
      font-size: 32px
      position: absolute
      z-index: 1
      top: -26px
      left: 64px
      margin: 0
      padding: 0
      span
        font-weight: 400, sans-serif
        font-size: 24px

  .first_content
    width: 66%
    margin: 0 3% 0 0

  .ueid-asset-container
    width: 100%
    z-index: 2
  .second_content
    width: 31%
    margin: 0
    flex-direction: column
    padding-top: 136px

  .first_content, .second_content
    display: inline-flex

  .overview_information
    margin: -32px auto 64px
    display: flex
    p
      max-width: 720px
    .customer_information, .ue_information
      width: 48%
    .customer_information
      margin-right: 2%
    .ue_information
      margin-left: 2%


  .second_content
    h4
      font-weight: 500
      font-size: 13px
      color: hsl(200, 16%, 16%)
      padding: 0
      width: 100%
      line-height: 1.3
      margin-bottom: 24px
      a
        color: hsl(270, 100%, 52%)
        font-weight: 800

  .asset-container
    width: 75%
    max-width: 840px
    padding: 0 32px
    margin: 0 0 64px

  .ueid-button-container
    display: block
    width: 100%
    margin-top: 24px
    button
      margin-right: 0

  .file-asset-button
    margin-top: 0

  .ueid_newsletter
    background: hsl(200, 12%, 88%)
    background: linear-gradient(hsl(200, 24%, 96%), hsl(200, 24%, 88%))
    h2, p
      color: hsl(200, 12%, 8%)
    &.customer_spotlight
      position: relative
      width: 75%
      left: 25%
      margin-bottom: 0

  .dark_text
    .back-link span, h1, h4
      color: hsl(200, 8%, 8%)
    .back-link svg .icon-stroke
      stroke: hsl(200, 8%, 8%) !important
      opacity: 0.5

  @media screen and (max-width: 1200px), print
    footer, .ueid_newsletter
      &.customer_spotlight
        width: 100%
        left: 0

  @media screen and (max-width: 800px)
    .user-research-library-asset-header
      height: 280px
    .first_content, .second_content
      width: 100%
      margin: 0 auto
    .main_page_content, .first_content, .second_content
      flex-direction: column
    .second_content
      padding-top: 48px
      padding-bottom: 40px
    .overview_information
      display: block
      margin: -136px auto 64px
    .overview_information .customer_information, .overview_information .ue_information
      width: 100%
      margin: 48px auto 0

  @media screen and (max-width: 640px)
    .main_page_content, .overview_information
      top: 40px
      margin-bottom: 64px
    .overview_information
      margin: 24px auto 80px
    .user-research-library-asset-header
      top: 64px
    .first_content
      margin: -88px auto 0
      h1
        top: -25px
    .second_content
      padding-bottom: 16px
    .testimonial_container, .bar_chart
      min-width: 0 !important
</style>
